const TRELLO_COLORS = [
  'blue', 'green', 'orange', 'red', 'yellow',
  'purple', 'pink', 'sky', 'lime', 'light-gray'
]

const LIST_COLORS = [
  '#F2D8D8', '#D8D8F2', '#E2F2F2', '#D8F2D8', '#F2EFE9',
  '#E9F2E6', '#E9E6F2', '#EFE2F2', '#F2E2EF', '#F2F2D8'];

// const REVERSED_COLORS = TRELLO_COLORS.reverse()

export default class {
  static projectColor(project) {
    // return REVERSED_COLORS[project.index % COLORS.length]
    return null
  }

  static scopeColor(scopeCard, project) {
    const index = project.scopeCardIds.indexOf(scopeCard.id)
    return TRELLO_COLORS[index % TRELLO_COLORS.length]
  }

  static listColor(list) {
    return LIST_COLORS[list.shapeUp.index % LIST_COLORS.length]
  }

  static completedColor() {
    return "#1f845a"
  }

  static secondaryColor() {
    return "#e8fbff"
  }

  static positiveColor() {
    // return "#2f3988"
    // return "#424d9d"
    // return "#5d6de7"
    return "#2196f3"
  }

  static iconButtonClasses() {
    return ["icon", "text", "x-small"]
  }

  static chipClasses() {
    return ["compact"]
  }
}
